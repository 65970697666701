import React from "react"
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      "בית התוכנה אפספורס מתמחה בפיתוח תוכנה ואפליקציות מקצה לקצה ובהתאמה אישית מלאה לצרכי ולדרישות הלקוח. אנו מציעים מגוון פתרונות פיתוח תוכנה המאפשרים למקסם את יכולות וביצועי העסק, כאשר מטרת הפיתוח היא לבנות מערכת חדשה ומותאמת לייעוד הספציפי שנקבע על ידי הלקוח. ",
      <>
        {`יש מגוון שיטות וטכנולוגיות לפיתוח תוכנה, ובשלב האפיון הצוות המיומן שלנו מסייע ללקוח לבחור את הפתרונות הנכונים והמתאימים ביותר למטרותיו וליעדיו העסקיים והשיווקיים. ראוי לציין כי פתרונות הפיתוח שלנו ממוקדים, יעילים ואפקטיביים, אנו מפתחים תוכנה בעזרת טכנולוגיות חדשניות ואיכותיות כמו `}
        <Link to={URL.FULL_STACK_SERVICE} active>פול סטאק מקצה לקצה</Link>,
        {` `}
        <Link to={URL.FRONT_END_SERVICE} active>פיתוח בפרונט אנד</Link>,
        {` `}
        <Link to={URL.SAAS_CONSULTING_SERVICE} active>פיתוח אפליקציות SaaS</Link>
        {` עוד, בדגש על חוויית משתמש ייחודית ומתקדמת.`}
      </>
    ],
  },
  section2: {
    h: "פתרונות פיתוח מקצה לקצה תחת קורת גג אחת",
    p: [
      "צוות הפיתוח שלנו באפספורס שולט בכל הטכנולוגיות הנדרשות לפיתוח תוכנה חדשה ובהתאמה אישית מלאה לצרכי הלקוח. אנו מתחילים את הפרויקט לפיתוח התוכנה החדשה באמצעות מסמך אפיון מפורט ומעמיק, המסמך מאפשר ללקוח להבין כיצד התוכנה הסופית תיראה ותתנהג, ולהבין את תרחישי הזרימה האפשריים שיאפשרו למשתמשים השונים לבצע את מלאכתם בקלות ובנוחות",
      "צוות הפיתוח המנוסה והמיומן שלנו בוחר את הטכנולוגיות המתאימות, ולמעשה הצוות מלווה את הלקוח לכל אורך שלבי הפיתוח, משלב האפיון של התוכנה, דרך שלבי הפיתוח ובניית המערכת באמצעות טכנולוגיות חדשניות בצד השרת ובצד הלקוח כדוגמת פייתון, Node.js, פרונט אנד, בק אנד, ריאקט, אנגולר ועוד. למעשה, אנו מספקים פתרונות פיתוח תוכנה מקצה לקצה ותחת קורת גג אחת, כך הלקוח זוכה לפתרון מקיף וכולל, חוסך עלויות, מקצר זמנים וזוכה לתוכנה חדשה וידידותית למשתמש בהתאמה אישית מלאה."
    ],
  },
  section3: {
    h: "תהליך פיתוח תוכנה על ידי צוות אפספורס",
    p: [
      "כאמור, יש מגוון טכניקות וגישות לפיתוח תוכנה, חלק מהשיטות מבוססות על פיתוח מתמשך והדרגתי של המוצר הטכנולוגי ויש שיטות המבוססות על פיתוח גמיש במודולים. באופן כללי, מחזור הפיתוח של תוכנה בסיסית, באנגלית SDLC – Software Development Life Cycle, המיועדת לצורך ספציפי המוגדר על ידי הלקוח, כולל את השלבים הבאים:",
    ],
    ul: [
      "פגישת ייעוץ והכרות ראשונית, בשלב זה הלקוח מגדיר מטרות בסיסיות ומתאמים ציפיות.",
      "חקר שוק מעמיק, בדיקת פתרונות קיימים ואפיון קהל המטרה.",
      "הגדרת מטרות ודרישות מהמוצר הסופי.",
      "ניתוח והבנת הבעיות שהתוכנה צריכה לפתור.",
      "תוכנית עבודה כללית להנחיית צוות פיתוח התוכנה ולבחירת הטכנולוגיות הנכונות.",
      "בניית התוכנה באמצעות פתרונות פיתוח מתקדמים ועדכניים.",
      "אפיון ועיצוב חוויית משתמש UI/UX.",
      "בדיקות תוכנה – QA.",
      "השקת התוכנה והטמעתה במערכת המחשוב אצל הלקוח.",
      "תמיכה טכנית זמינה, תיקון באגים ותחזוקה שוטפת."
    ]
  },
  section4: {
    p: [
      "חשוב לדעת כי בתהליך פיתוח התוכנה לעיתים יש לחזור על אחד מהשלבים כמה פעמים, והמטרה היא תוכנה חדשה ואופטימלית ללא באגים המתפקדת בצורה אופטימלית אצל הלקוח הסופי."
    ]
  },
  section5: {
    h: "פיתוח תוכנה Full Stack",
    p: [
      "בית התוכנה אפספורס מציע מגוון פתרונות איכותיים ומתקדמים לפיתוח תוכנה, צוות הפיתוח שלנו כולל מפתחי Full Stack מיומנים השולטים בשפות תכנות ובטכנולוגיות פיתוח עדכניות וחדישות, משני הצדדים של התוכנה, כלומר הן בצד השרת והן בצד הלקוח, בשילוב מסדי נתונים, לכן אנו מציעים פיתוח מקצה לקצה.",
      <>
        {`ראוי לציין כי פיתוח בפול סטאק מאפשר לנו לפתח תוכנת שרת – לקוח חדשה במגוון דרכים ובאמצעות שפות תכנות חדישות, פלטפורמות פיתוח עדכניות ועוד. פיתוח פול סטאק מקצה לקצה מחייב ידע, ניסיון ושליטה מלאה בטכנולוגיות ובשפות תכנות שונות, וכדי להבטיח תוכנה מושלמת העונה לכל דרישות הלקוח והמשתמשים הפוטנציאליים, אנו משקיעים זמן ומשאבים רבים `}
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>באפיון ובעיצוב UI/UX</Link>
        {` על מנת להבטיח חוויית משתמש ייחודית ומותאמת לאסטרטגיית השיווק של הלקוח.`}
      </>,
      "פתרונות פיתוח התוכנה שלנו כוללים פיתוח לפי דרישה, לאחר אפיון המוצר וחקר שוק. פיתוח תוכנה מקצה לקצה בטכנולוגיית full stack מבטיח ללקוח כיסוי מלא של 360 מעלות, ולמעשה הלקוח משתתף בשלב אפיון התוכנה ובהמשך הצוות שלנו אחראי על כל הצדדים הטכניים והוויזואליים של המערכת, בדגש על בחירה טכנולוגיות חדישות ועיצוב ממשקים המבטיחים חוויית משתמש נוחה, קלה ומוצלחת."
    ],
  },
  section6: {
    h: "למה לבחור בנו לפתרונות פיתוח תוכנה?",
    p: [
      <>
        <Link to={URL.HOME} active>בית התוכנה אפספורס</Link>
        {` מציע צוות מפתחי פרונט אנד, בק אנד ופול סטאק, כל המפתחים מנוסים בטכנולוגיות מתקדמות כגון: פייתון, ג'אווה, ריאקט, Node.js ועוד. אנו משלבים את הטכנולוגיות האיכותיות והמתקדמות ביותר עם עולמות התוכן והצרכים העסקיים של הלקוח. כבית תוכנה מוביל אנו מספקים פתרונות פיתוח תוכנה לעסקים, ליזמים, לחברות הייטק וסטארט-אפים ולארגונים שונים במגזר הפרטי והציבורי.`}
      </>,
      "מתודולוגיות פיתוח התוכנה אצלנו הן מתקדמות ומותאמות באופן מושלם לצרכי ולדרישות הלקוח, הצוות שלנו מוביל את פרויקט הפיתוח ודואג להשלים את פיתוח המוצר בזמן ובהתאם ליעדי התקציב והמטרות העסקיות והשיווקיות של הלקוח. במהלך הפיתוח אנו שולחים דיווחים ועדכונים שוטפים ומקפידים על שקיפות מלאה מול הלקוח."
    ],
  },
};