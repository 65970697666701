import * as React from "react";
import { graphql } from 'gatsby';
import Blog, { BlogSubHeader, BlogUnorderListItem, BlogUnorderList } from '../../components/Blog';
import { Paragraph } from "../../components/Common";
import { HE } from '../../blogs/solutions_for_software_development';
import { HowToDoSoftwareDesignRight, DesignRules, SolutionsForSoftwareDevelopment, WebDevelopmentProcesses } from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const title = "Solutions for software development";

const articleSchemaData = {
  headline: title,
  description: 'SEO description solutions for software development',
  image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fbenefits-of-working-with-software-company.jpg.jpg?alt=media&token=9402638c-ab64-4fd1-bf85-957cc62b4121',
  datePublished: '2022-03-05'
};

const SolutionsForSoftwareDevelopmentBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(title, BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={SolutionsForSoftwareDevelopment.url}
      banner="/solutions-for-software-development/banner.jpg"
      relatedBlogs={[DesignRules, HowToDoSoftwareDesignRight, WebDevelopmentProcesses]}
      title={title}
      seo={{
        title: 'SEO title solutions for software development',
        description: 'SEO description solutions for software development',
        image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fbenefits-of-working-with-software-company.jpg.jpg?alt=media&token=9402638c-ab64-4fd1-bf85-957cc62b4121',
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map(p => <Paragraph>{p}</Paragraph>)}


      <BlogSubHeader>
        {HE.section2.h}
      </BlogSubHeader>
      {HE.section2.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section3.h}
      </BlogSubHeader>
      {HE.section3.p.map(p => <Paragraph>{p}</Paragraph>)}

      <Paragraph>
        <BlogUnorderList>
          {HE.section3.ul.map(p => (
            <BlogUnorderListItem>
              {p}
            </BlogUnorderListItem>
          ))}
        </BlogUnorderList>
      </Paragraph>
      {HE.section4.p.map(p => <Paragraph>{p}</Paragraph>)}


      <BlogSubHeader>
        {HE.section5.h}
      </BlogSubHeader>
      {HE.section5.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section6.h}
      </BlogSubHeader>
      {HE.section6.p.map(p => <Paragraph>{p}</Paragraph>)}
    </Blog>
  )
}

export default SolutionsForSoftwareDevelopmentBlog;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;